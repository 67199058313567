import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home/index.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/kaixinhu",
    name: "Kaixinhu",
    component: () => import(/* webpackChunkName: "KaiXinHu" */ "../views/Product/KaiXinHu.vue"), //../views/Product/KaiXinHu.vue
    meta: {
      title: "产品中心开心虎",
    },
  },
  {
    path: "/yibeiduo",
    name: "yibeiduo",
    component: () => import(/* webpackChunkName: "yibeiduo" */ "../views/Product/YiBeiduo.vue"), //../views/Product/YiBeiduo.vue
    meta: {
      title: "产品中心益贝多",
    },
  },
  {
    path: "/meishiyouxiang",
    name: "meishiyouxiang",
    component: () => import(/* webpackChunkName: "meishiyouxiang" */ "../views/Product/MeiShiYouXiang.vue"), //../views/Product/MeiShiYouXiang.vue
    meta: {
      title: "产品中心每食优享",
    },
  },
  {
    path: "/kaixinhugongchangduan",
    name: "kaixinhugongchangduan",
    component: () => import(/* webpackChunkName: "kaixinhugongchangduan" */ "../views/Product/Kaixinhugongchangduan.vue"), //../views/Product/Kaixinhugongchangduan.vue
    meta: {
      title: "产品中心开心虎管理",
    },
  },
  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "xueyoubao" */ "../views/Test/index.vue"),
    meta: {
      title: "测试页面",
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
