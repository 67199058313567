
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  setup() {
    const router = useRouter();
    const handleSelect = (key: string) => {
      console.log(key);
      router.push({
        name: key,
      });
    };
    return {
      handleSelect,
      options: [
        {
          label: "首页",
          key: "Home",
        },
        {
          label: "产品中心",
          key: "prod",
          children: [
            {
              label: "开心虎",
              key: "Kaixinhu",
            },
            {
              label: "益贝多",
              key: "yibeiduo",
            },
            {
              label: "商城",
              key: "shangcheng",
            },
          ],
        },
        {
          label: "关于我们",
          key: "About",
        },
      ],
    };
  },
});
