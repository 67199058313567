
import { defineComponent } from "vue";
// import JcCarouse from "./JcCarouse/index.vue";
import JcProdCar from "./JcProdCar/index.vue";
import { useLoadingBar } from "naive-ui";
import { useStore } from "vuex";
import wowmixin from "../../mixins/wow";
export default defineComponent({
  name: "home",
  components: { JcProdCar },
  props: {
    p: {
      type: [String, Number],
      default: 2,
    },
  },
  setup() {
    const store = useStore();
    const loadingBar = useLoadingBar();
    store.commit("setLoadingBar", loadingBar);
    wowmixin();
    return {
      imgUrl: require("../../assets/home-bg.png"),
      myFw: "我们的服务",
      sliders: [
        {
          message: "开心虎",
          brief: "食安咨询、监管食堂，了解孩子在校食品安全。",
          link: "/kaixinhu",
          isVisibility: true,
        },
        {
          message: "益贝多",
          brief: "让用户在开心虎旗下的任何产品中都能享受立减优惠",
          link: "/yibeiduo",
          isVisibility: true,
        },
        {
          message: "每食优享",
          brief: "“开心虎”为第三方央厨企业做品牌建设",
          link: "/meishiyouxiang",
          isVisibility: true,
        },
        {
          message: "开心虎管理",
          brief: "“开心虎”为第三方央厨企业做工作流程化管理",
          link: "/kaixinhugongchangduan",
          isVisibility: true,
        },
        {
          message: "交易服务平台",
          brief: "食品信息溯源，食堂监控，上报健康资质",
          link: "/",
          isVisibility: false,
        },
        {
          message: "健康安全",
          brief: "对餐饮单位的陌生人、卫生、着装等进行分析预警。",
          link: "/",
          isVisibility: false,
        },
      ],
      prodCenter: "PRODUCT",
      prodMsg:
        "“益贝多”—为了给用户提供便捷，可靠的服务， 倾力打造了食源健康、快速配送的益贝多APP。用户在家动动手指，就能享受新鲜瓜果送货上门的服务！新鲜蔬菜每日上新，优惠立减，收藏加购，不满意随时退换货等多样化功能，做到了真正潜用户分忧，为用户提供更简单便捷的生活方式，我们一直在前进。",
      prods: {
        kxh: {
          title: "开心虎",
          msg: "智能管理：大数据、云计算的开发应用，实现从下单到采购到生产到配送全流程智慧化的统计分析……",
        },
        ybd: {
          title: "益贝多",
          msg: "益贝多是一款开心虎旗下的商城软件，包括商品购买、优惠立减活动……",
        },
        msyx: {
          title: "每食优享",
          msg: "每日菜谱查看，食源追溯，餐费支付等多样化功能，是“开心虎”为第三方央厨企业用打造品牌建设而开发的app……",
        },
        kxhgcd: {
          title: "开心虎管理",
          msg: "开心虎管理app，用于工厂员工工作任务查看，工作考核以及学校供餐计划查看等辅助办公软件，目的是提高工厂员工的办事效率……",
        },
      },

      bigtitle1: "企业简介",
      indexCard: [
        {
          imgurl: require("../../assets/about/a5.png"),
          msg: "晟校科技网络有限公司位于北京通州区新华街道京杭府，公司在健康校园配餐，新鲜食材配送，食源追溯等互联网现代化领域拓展多年，积累了丰富的技术经验，拥有成套智能管理解决方案，现已成为校园配餐现代化技术领域的领先企业，公司注册资金2.5亿人民币，拥有多项技术专利，总部技术人员研发实力雄厚，人数近500人，目前在全国26省直辖市设立子公司，规划三年内将技术推广全国，晟校科技以将健康的营养餐配送给用户为己任，当作企业最大的公益，全力助推饮食现代化，开足马力，撸起袖子加油干，誓为每一位学子的健康成长而努力！",
        },
      ],
    };
  },
});
