
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  props: ["title", "msg", "topath", "imgsrc"],
  setup(props) {
    const router = useRouter();
    const to = () => {
      router.push({ path: props.topath });
    };
    return {
      to,
    };
  },
});
