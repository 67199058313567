
import { defineComponent, ref, onMounted, nextTick } from "vue";
import HeaderBar from "./components/HeaderBar/index.vue";
import FooterBar from "./components/FooterBar/index.vue";
export default defineComponent({
  components: {
    HeaderBar,
    FooterBar,
  },
  setup() {
    const scrollbar = ref(null);
    const headerShadow = ref(false);
    const backTopRight = ref<number>(100);
    onMounted(() => {
      const resizeFun = () => {
        const offsetWidth = document.body.offsetWidth;
        offsetWidth < 768 ? (backTopRight.value = 10) : (backTopRight.value = 100);
      };
      const scrollFun = () => {
        const target: any = document.querySelector("html");
        target.scrollTop > 100 ? (headerShadow.value = true) : (headerShadow.value = false);
      };
      nextTick((): void => {
        window.addEventListener("resize", resizeFun);
        window ? window.addEventListener("scroll", scrollFun) : document.addEventListener("scroll", scrollFun);
      });
    });
    return {
      scrollbar,
      headerShadow,
      backTopRight,
    };
  },
});
