
import { defineComponent } from "vue";
import QrCode from "./QrCode/index.vue";
export default defineComponent({
  components: {
    QrCode,
  },
  setup() {
    const a = 1;
    interface TextVal {
      beian?: string;
      phone?: any;
      post?: any;
      address?: any;
      qrCode?: any;
    }
    const text: TextVal = {
      // beian: "©京ICP备17074044号 晟校科技（北京）有限公司",
      beian: "京ICP备2022024632号 晟校科技（北京）有限公司",
      phone: {
        text: "客服热线",
        phone: "010-8622 1923",
        time: "周一至周五：9:00-18:30",
      },
      post: {
        text: "邮箱",
        email: "service@keyidabj.com",
      },
      address: {
        text: "地址",
        address: "北京市通州区新华东街289号2号楼京杭府",
      },
      qrCode: {
        kxhWx: {
          src: require("../../assets/qrcode/wxCode.jpg"),
          message: "微信服务号",
        },
        kxhApp: {
          src: require("../../assets/qrcode/kxh-code.png"),
          message: "手机App下载",
        },
        // jiaobeiApp: {
        //   src: require("../../assets/qrcode/kxh-code.png"),
        //   message: "手机App下载",
        // },
        // jiaobeiWx: {
        //   src: require("../../assets/qrcode/wxCode.png"),
        //   message: "微信服务号",
        // },
      },
    };
    return {
      a,
      text,
    };
  },
});
