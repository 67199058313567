import router from "./router/index";
import store from "./store/index";
router.beforeEach((to) => {
  store.getters.loadingBar && store.getters.loadingBar.start();
  const title = to.meta.title;
  document.title = process.env["VUE_APP_TITLE"] + "-" + title;
});
router.afterEach(() => {
  // 切换页面时将滚动条置到顶部
  const a: any = document.querySelector("html");
  a.scrollTop = 0;
  setTimeout(() => {
    store.getters.loadingBar && store.getters.loadingBar.finish();
  }, 100);
});
